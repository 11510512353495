<template>
  <div id="term_condition">
      <!-- <h2 class="step_question_title">
        Terms and Conditions – Skyline Limited 条款和条件
      </h2> -->
      <p class="claim_text">Approval of your application for Skyline Tech Limited services is subject to Skyline Tech Limited's client approval process and is at the sole discretion of Skyline Tech Limited. Skyline Tech Limited may not approve your application for services if the information requested in this Account Opening Form is not supplied or is incorrect.</p>
      <p class="claim_text mt-4">Skyline Tech Limited may also request further information from you to assess your application for services. Failure to provide any such further information may result in your application being delayed and/or refused.</p>
      <p class="claim_text mt-4">By completing and signing this Account Opening Application Form you understand that Skyline Tech Limited may pass on your information to one or more third party credit reporting entities to check your creditworthiness. This information may be stored by these entities, and by signing this application you consent to us providing such information and the storage and use of such information by these third-party credit reporting entities.</p>
      <p class="claim_text mt-4">By completing and signing this Account Opening Application Form you are requesting certain services from Skyline Tech Limited. Skyline Tech Limited may in turn use the services of IE Money
      Limited and /or affiliated entities and/or United Au Ltd /or affiliated entities and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.
      to provide some or all of these services. If we accept your application for services, the agreement between us will comprise the Account Opening Application Form and the Skyline Tech Limited Terms and Conditions set out in Appendix One below.</p>
      <p class="claim_text mt-4">Where Skyline Tech Limited in turn uses the services of IE Money Limited and/or affiliated entities and United Au Ltd/or affiliated entities and/or CYS Global Remit PTE Limited or Legendary Trading Ltd orGold Town Investment Limited or Currency Cloud Ltd. to provide some or all of these services, in addition the IE Money Limited and/or affiliated entities and/or affiliated entities Limited and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. Terms and Conditions set out in Appendix Two below will form part of the agreement.</p>
      <p class="claim_text"></p>
      <p class="claim_text mt-4">1. I warrant that:</p>
      <ul class="step_ul_style">
        <li>All statements and information are true and fairly represent my position (financial and otherwise);</li>
        <li>The funds used in trading are of legitimate source, and I am compliant with the provisions of all laws affecting the foreign exchange services</li>
        <li>I will immediately give written notice to Skyline Tech Limited of a change of name or address.</li>
        </ul>
      <p class="claim_text mt-4">2. I acknowledge that:</p>
      <ul class="step_ul_style">
        <li>The information provided by me is required to verify my identity in accordance with current legislation.</li>
        <li>The establishment of the account may be delayed or declined if I am unable to meet all identity verification requirements.</li>
        <li>Additional information may be required of me prior to the establishment of the account or from time to time, as considered appropriate by the Manager.</li>
        </ul>

      <p class="claim_text mt-4">Restrictions may be placed on the account if I am unable to satisfy all identity verification requirements or ongoing account monitoring requirements</p>
      <p class="claim_text mt-4">You may give Skyline Tech Limited instructions electronically (including instructions via email, SMS and another other instant messaging) or in writing. Skyline Tech Limited may rely on the electronical or written instructions confirmed by you to conduct the orders. In the event that you default in fulfilment of your order on the value date of transaction stated in the confirmation, you agree that Skyline Tech Limited shall have the right to liquidate your position on the same working date at fair market rate as determined in the absolute discretion of Skyline Tech Limited and impose an administrative fee of not more than NZD$1,000.00. You shall further acknowledge and agree that your position may be liquidated at a loss, and you will be liable for any and all exchange rate losses incurred by Skyline Tech Limited in respect of the outstanding transaction so liquidated.</p>
      <p class="step_question_title mt-6">Appendix One: Terms and Conditions - Skyline Tech Limited</p>
      <ol>
        <li class="font-500">Description of Services
          <ul class="step_ul_style">
            <li>Skyline Tech Limited agrees to provide the following professional services to the client:</li>
            <li>Provide for the exchange of New Zealand currency into foreign currencies and the exchange of foreign currencies into New Zealand currency. Skyline Tech Limited may use the services of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or affiliated entities and/or CYS Global Remit PTE or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. to provide some or all of theseservices to the client</li>
            <li>Dealing in non-cash payment products</li>
            <li>Provide any relevant online information inquiry service</li>
          </ul>
        </li>
        <li class="font-500 mt-2">Written terms and conditions to prevail
          <p class="claim_text mt-2">These terms and conditions apply to all of your Applications/Agreements with us, whenever those Application/Agreements were entered into. To the extent there is any inconsistency or conflict between these terms and conditions and any previous terms and conditions disclosed by us to you, these terms and conditions will apply and you agree, to the extent applicable, that adoption of these terms and conditions will constitute an agreed variation of any existing Applications/Agreements you have with us.</p>

        </li>
        <li class="font-500 mt-2">Client Warranties
          <p class="claim_text mt-2">
            Client is responsible for ensuring that the use of the services is in compliance with all applicable laws, including, without limitation, laws intended to prevent money-laundering, gaming and laws intended to protect consumers against unfair debt collection. Client and not Skyline Tech Limited shall be responsible for all taxes associated with the services and/or the transfer of any funds performed hereunder, including, without limitation, income tax, withholding taxes, value added taxes (VAT), goods and services taxes (GST), sales taxes, customs and excise duty and use taxes. If it is determined that Skyline Tech Limited may owe any such taxes, Client shall promptly reimburse Skyline Tech Limited therefore.
          </p>
        </li>
        <li class="font-500 mt-2">Resolution of Customer Inquiries
          <p class="claim_text mt-2">
            Skyline Tech Limited shall be responsible for responding to and resolving inquiries and complaints from Clients arising out of the use of Skyline Tech Limited’s services by Clients.
          </p>
        </li>
        <li class="font-500 mt-2">Compliance with Laws
          <p class="claim_text mt-2">Clients agree to indemnify Skyline Tech Limited and hold it harmless from any and all claims, losses, damages, liabilities or expenses (including attorney’s fees) of every nature and kind incurred as a result of Client’s failure or alleged failure to comply with applicable laws, orders, rules and regulations. Any act or omission in breach of any such law, order, rule or regulation shall constitute a material breach of this agreement. Client represents and warrants that all information disclosed to Skyline Tech Limited in connection with this Application Form and Agreement for Services, is true, accurate and complete. This representation and warranty shall be deemed an ongoing representation and warranty from Client.</p>
        </li>
        <li class="font-500 mt-2">Indemnification/Limitation of Liability
          <ul class="step_ul_style">
            <li>Client will defend, indemnify and hold harmless Skyline Tech Limited from any claims, damages or losses (“Losses”) relating to all services processed hereunder, except to the extent such Losses arise by reason of Skyline Tech Limited’s breach of the terms and conditions of this Application Form and Agreement for Services. In such an instance Skyline Tech Limited's only responsibility for damages on error, delay, non-payment, negligence (apart from Skyline Tech Limited responsibility to assure re-payment of the principal amount actually received from Client or a Customer), shall be limited to a refund of the service fee paid in connection with the affected transaction.</li>
            <li>Under no circumstances will Skyline Tech Limited be liable to Client or Customers for indirect, exemplary, incidental, special, or consequential damages, whether or not either party knew or had reason to know that they might be incurred. In no event shall Skyline Tech Limited’s aggregate liability to Client under this Agreement for any reason exceed the lesser of the amount of fees paid hereunder to Skyline Tech Limited during the sixth month period prior to when the claim arose or fifty thousand dollars ($50,000 NZD).</li>
            <li>In particular, the client agrees that where Skyline Tech Limited is unable to complete the provision of services to the client as a result of external events, (e.g. a collapse of financial services, the freezing of Skyline Tech Limited or its agents or associated companies bank accounts by any bank, government or authority, force majeure etc), Skyline Tech Limited’s only obligation is to repay an amount equivalent to the amount originally paid to Skyline Tech Limited, as and when it is reasonably able to do so.</li>
          </ul>
        </li>
        <li class="font-500 mt-2">Terms and Termination
          <p class="claim_text mt-2">Skyline Tech Limited may terminate or suspend this Application Form and Agreement for Services or any services to the client at any time without notice if Skyline Tech Limited determines in its sole discretion that the client is using the services to transmit funds</p>
          <ul class="step_ul_style">
            <li>For gambling (including, without limitation, betting on sporting events) to or from New Zealand or any other jurisdiction where such transmission may be illegal</li>
            <li>For any other purpose which may violate applicable law, banking regulation or Skyline Tech Limited policy.</li>
          </ul>
          <p class="claim_text mt-2">Skyline Tech Limited may also terminate or suspend this agreement or the services without notice to the client if Skyline Tech Limited determines that any information provided by the client is false or misleading or that the client has breached or defaulted under any term of this agreement. Skyline Tech Limited may terminate this Application Form and Agreement for Services at its sole discretion upon thirty (30) days written notice.</p>
        </li>
        <li class="font-500 mt-2"> IE Money Limited and/or a United Au Ltd/or affiliated entities and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.
          <p class="claim_text mt-2">Skyline Tech Limited may at its sole discretion utilise the services of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. to provide services to the Client. The client consents to this and agrees that the IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE or Gold Town Investment Limited or Currency Cloud Ltd. standard trading Terms and Conditions shall apply to the provision of such services.The client acknowledges that these terms and conditions which are set out in Appendix 2 to the Application Form and Agreement for Services have been read and that the client is bound by
such terms and conditions.</p>
        </li>
        <li class="font-500 mt-2">Miscellaneous
          <ul class="step_ul_style">
            <li>This Application Form and Agreement for Services, together with any addenda attached hereto, represents the entire agreement of the parties with respect to the subject matter hereof, and may be modified only in writing signed by duly authorized representatives of the parties to be bound thereby. The client may not assign or otherwise transfer its rights and obligations pursuant to this Agreement without Skyline Tech Limited’s prior written consent. This Application Form and Agreement for Services and the performance of the parties hereunder is governed by the laws of the New Zealand, as applicable, without regard to their conflicts of laws rules which might require the application of the law of another jurisdiction.</li>
            <li>High Risk countries are Algeria, Ecuador, Iceland, Indonesia, Myanmar, Mongolia, Pakistan, Syria, Turkey and Zimbabwe.</li>
            <li>Politically Exposed Persons are persons with prominent public functions and/or are senior public figures, as defined in the AML/CFT ACT. Disclaimer Statement</li>
          </ul>
          <p class="claim_text mt-2">All content on www.iemoney.co.nz and all of their sub domains provided by Skyline Tech Limited or other third-party content providers does not take into account any of your individual financial conditions, and is not appropriate for the purposes of making a decision to carry out a transaction or trade. Neither Skyline Tech Limited nor other third-party content providers shall be liable for any errors, inaccuracies or delays in content, or for any actions taken in reliance thereon.</p>
        </li>
      </ol>
      <p class="claim_text mt-2">
        Skyline Tech Limited EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, AS TO THE ACCURACY OF ANY THE CONTENT PROVIDED, OR AS TO THE FITNESS OF THE INFORMATION FOR ANY PURPOSE.
      </p>
      <p class="step_question_title mt-6">Appendix Two: IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd Terms or Gold Town Investment Limited or Currency Cloud Ltd. and Conditions (T&S) for Foreign Exchange Services</p>
      <ol>
        <li class="font-500 mt-2">Application
          <ul class="step_ul_style">
            <li>These T&C apply to all of your Applications/Agreements with us, whenever those Application/Agreements were entered into. To the extent there is any inconsistency or conflict between this T&C and any previous terms and conditions disclosed by us to you, this T&C will apply and you agree, to the extent applicable, that adoption of this T&C will constitute an agreed variation of any existing Applications/Agreements you have with us.</li>
            <li>By requesting services from IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd., you agree to share your personal information with IE Money Limited and/or affiliated entities and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. for the purpose of account opening application and foreign exchange services.</li>
            <li>Approval of your application is subject to IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town  Investment Limited or Currency Cloud Ltd.’s client approval processes and is at the sole discretion of IE Money Limited and/or affiliated entities and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.. IE Money Limited and/or affiliated entities to IE Money Ltd and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may not assess or approve your application if the information requested is not supplied or does not meet the requirements. IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may also request further information from you, in addition to the information requested in the Application Form, in order to assess and approve your application. Failure to provide any further information that is requested by IE Money Limited and/or affiliated entities to IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may result in assessment and/or approval of your application being delayed and/or refused.</li>
          </ul>
        </li>
        <li class="font-500 mt-2">Your Warranties and Acknowledgement
          <p class="my-4">You represent and warrant to us that:</p>
          <ul class="step_ul_style">
            <li>All statements and information are true and fairly represent your position (financial and otherwise);</li>
            <li>Once your application is approved, this T&C constitute your legal, valid and binding obligations and is enforceable in accordance with the terms of the foreign exchange services;</li>
            <li>The funds used in trading are of legitimate source, and you are compliant with the provisions of all laws affecting the foreign exchange services; 2.4You will immediately give written notice to us of a change of name or address.</li>
            </ul>
          <p class="font-500 my-4">You acknowledge that:</p>
          <ul class="step_ul_style">
            <li>The information provided by you is required to verify your identity in accordance with current legislation.</li>
            <li>The establishment of the account may be delayed or declined if you are unable to meet all identity verification requirements.</li>
            <li>Additional information may be required of you prior to the establishment of the account or from time to time, as considered appropriate by the account manager/compliance officer.</li>
            <li>Restrictions may be placed on the account if you are unable to satisfy all identity verification requirements or ongoing account monitoring requirements.</li>
          </ul>
        </li>

        <li class="font-500 mt-2">Privacy Statement
          <p class="font-500 my-4">In completing this Application Form you may supply IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. with information which is Personal Information subject to New Zealand’s Privacy Act 2020 (as amended).</p>
          <ul class="step_ul_style">
            <p class="font-500 my-4">You acknowledge that the information that we obtain about you will be used for the purposes of:</p>
            <li>Assessing my/our current and on-going credit worthiness;</li>
            <li>Providing and administering your trading account;</li>
            <li>Maintaining the integrity and effectiveness of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.’s credit records, the credit records of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.’s association company, and those of credit reporting agencies;</li>
            <li>Collecting, compiling and/or reviewing information, including both positive and negative information about your credit history and credit rating;</li>
            <li>Marketing goods and services provided by the IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd and its assigns or related companies unless you notify IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. otherwise;</li>
            <li>IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. will not supply your financial adviser (if any) with information about your accounts unless you instruct IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. to do so in writing. You are entitled to contact IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. and request access to your Personal Information.</li>
          </ul>
          <ul class="step_ul_style">
            <p class="font-500 my-4">You irrevocably authorise us for any and all of the foregoing purposes:</p>
            <li>To obtain information that is reasonably required concerning us from any source including, but not limited to, sanctions screening agencies, credit reporting agencies, past and current credit providers. You hereby permit all of these parties to provide such information to IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.;</li>
            <li>To collect, retain and use the Information, including for the purpose of e-mail and other electronic or social media marketing or communication services between you and IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. and/or its assigns or related companies used in connection with the purposes outlined above;</li>
            <li>In the event that this application is declined, to disclose the reasons for the decline to the Dealer, Introducer or Broker, as the case maybe;</li>
          </ul>

        </li>

        <li class="font-500 mt-2">Sanction Checks
          <p class="font-500 my-4">By signing this T&C, you understand that IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may be asking you for your Personal Information which may be passed on to one or more third-party sanction screening agencies to conduct a global sanctions and enforcement check on you which will be used for the purposes of determining your eligibility. As a result of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.’s enquiry to that/those sanction screening agencies:</p>
          <ul class="step_ul_style">
            <li> That/those sanctions screening agencies may provide IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. with information, including Personal Information, about you;</li>
            <li> IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may provide your Personal Information to that/those sanction screening agencies, and that/those sanction screening agencies will hold your Personal Information on their systems and use it to provide their sanctions screening service, which may include providing your Personal Information to other third parties that may use that/those sanctions screening agencies’ services;</li>
          </ul>
        </li>
      </ol>
      <p class="claim_text my-2">
        IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd. may use one or more sanctions screening agencies’ services in the future for purposes related to the provision of IE Money Limited and/or affiliated entities and/or United Au Ltd and/or CYS Global Remit PTE Limited or Legendary Trading Ltd or Gold Town Investment Limited or Currency Cloud Ltd.’s services to you. This may include using that/those sanctions screening agencies’ monitoring services to receive update if any of the information held about you changes.
      </p>
      </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.step_container {
  background: rgb(248, 248, 248);
  padding: 0 1rem;
}

.step_sub_title {
  margin: 20px 0;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
ol{
  margin-top: 1rem;
  list-style-type: decimal;
  padding-left: 25px;
  font-size: 15px;
}

.claim_text{
  font-weight: 300;
  font-size:15px;
  line-height: 24px;
}
.mt-10px{
  margin-top: 10px;
}
.mt-20px{
  margin-top: 20px;
}
.mt-40px{
  margin-top: 40px;
}
.step_ul_style {
  list-style-type: inside;
  list-style-type: disc;
  font-size: 15px;
  padding-left: 2rem;
  font-weight: 300;
  margin-top: 1rem;
}
.step_ul_style li {
  line-height: 24px;
}
.marginX20{
  margin: 20px 0;
}
.generate_btn{
  border: 1px solid #ea7840;
  color: #ea7840;
  padding: 10px;
  font-size: 15px;
  font-weight: 300;
  background-color: #fff;
  outline: none;
  margin-top: 5px;
  transition: .2s;
}
.generate_btn:hover{
  background-color:#ea7840;
  color: #fff;
}
.generate_btn:disabled{
  cursor: not-allowed
}
.general_sign_container{
  margin-top: 10px;
  background: #f8f8f8;
  padding:10px;
}
.general_sign_title{
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.general_sign_url{
  font-weight: 300;
  margin-top:5px;
  white-space: normal;
  word-break: break-all;
}
.copy_link_btn{
  background-color: #ea7840;
  color: #fff;
  padding: 3px;
  font-size: 14px;
  transition: .2s;
  outline: none;
  display: block;
  margin: 5px 0;
}
.copy_link_btn:hover{
  background: #f09f74;
}
.copy_success_text{
  font-weight: 300;
  color: red;
  font-size: 13px;
}
.mod_antd_checkbox {
  font-weight: bold;
  font-size: 17px!important;
  color: rgba(0, 0, 0, 0.85);
}
.mod_antd_checkbox >>> .ant-checkbox-inner{
  border-color: rgba(0, 0, 0, 0.85);
  width: 30px;
  height: 30px;
}
.mod_antd_checkbox >>> .ant-checkbox-inner::after{
  width: 9px;
  height: 18px;
}
.ant-form >>> label{
  font-size: 15px;
}
</style>
